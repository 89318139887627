import { createSlice } from "@reduxjs/toolkit";

const propertySlice=createSlice({
    name:"Property",
    initialState:{
        propertyFilter:{
            location: "",
            property_type_id:"",
            minPrice: "",
            maxPrice: "",
            
            hashtag_id: "",
            price:"",
            latest:"",
          
          }
    },
    reducers:{
       setPropertyFilter:(state,actions)=>{
            let {key,value}=actions.payload
            // console.log(key,value)
            if (key in state.propertyFilter) {
                state.propertyFilter[key] = value;
              } 
       },
       setPropertyPriceRange:(state,actions)=>{
        let {minPrice,maxPrice}=actions.payload
        state.propertyFilter.minPrice = minPrice;
        state.propertyFilter.maxPrice = maxPrice;
      },
      resetPropertyFilter :(state,actions)=>{
        state.propertyFilter={
            location: "",
            property_type_id:"",
            minPrice: "",
            maxPrice: "",
           
            hashtag_id: "",
            price:"",
            latest:"",
           
          }
      },
    }
})

export const {setPropertyFilter,setPropertyPriceRange,resetPropertyFilter} = propertySlice.actions
export default propertySlice.reducer